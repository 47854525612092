import { JSXElementConstructor } from "react";
import { cx } from "../classnames";

interface BadgeProps {
	leadingIcon?: JSXElementConstructor<{ className?: string }>;
	className?: string;
	iconColor?: string;
	children?: React.ReactNode;
}

export const Badge: React.FC<BadgeProps> = (props) => {
	return (
		<div
			className={cx(
				"border-stroke-secondary flex h-7 w-fit items-center gap-1 overflow-x-hidden text-ellipsis rounded-lg border px-[10px]",
				props.className,
			)}
		>
			{props.leadingIcon ? (
				<props.leadingIcon className={`${props.iconColor} h-4 w-4`} />
			) : null}
			{props.children}
		</div>
	);
};
