import { ImgHTMLAttributes, useMemo, useState } from "react";
import { cx } from "../classnames";
import Image from "next/image";

export interface AvatarProps {
	src?: string;
	alt?: string;
	size:
		| "4xs"
		| "3xs"
		| "2xs"
		| "xs"
		| "sm"
		| "md"
		| "lg"
		| "xl"
		| "2xl"
		| "3xl";
	className?: string;
	useNextImage?: boolean;
}

export const Avatar: React.FC<AvatarProps> = ({
	className,
	src,
	alt,
	size,
	useNextImage = true,
}) => {
	const [isError, setIsError] = useState(() => false);

	const dimensions = useMemo(() => {
		if (size === "xs") {
			return 24;
		} else if (size === "sm") {
			return 32;
		} else if (size === "md") {
			return 40;
		} else if (size === "lg") {
			return 48;
		} else if (size === "xl") {
			return 56;
		} else if (size === "2xl") {
			return 64;
		} else if (size === "3xl") {
			return 96;
		} else if (size === "2xs") {
			return 20;
		} else if (size === "3xs") {
			return 18;
		} else if (size === "4xs") {
			return 16;
		}
	}, [size]);

	const ImageComponent = useNextImage
		? Image
		: (props: ImgHTMLAttributes<HTMLImageElement>) => <img {...props} />;

	const textClassName = useMemo(() => {
		if (size === "4xs") {
			return "text-overline-xs";
		} else if (size === "lg" || size === "xl") {
			return "text-paragraph-md";
		} else if (size === "2xl" || size === "3xl") {
			return "text-paragraph-xl";
		} else {
			return "text-paragraph-xs";
		}
	}, [size]);

	return (
		<div
			className={`text-text-primary ${cx(
				className,
				"bg-slate-5 rounded-solis-full font-repro flex h-6 w-6 items-center justify-center",
				size === "3xs" && "h-[18px] min-h-[18px] w-[18px] min-w-[18px]",
				size === "2xs" && "h-5 min-h-5 w-5 min-w-5",
				size === "xs" && "h-6 min-h-6 w-6 min-w-6",
				size === "sm" && "h-8 min-h-8 w-8 min-w-8",
				size === "md" && "h-10 min-h-10 w-10 min-w-10",
				size === "lg" && " h-12 min-h-12 w-12 min-w-12",
				size === "xl" && " h-14 min-h-14 w-14 min-w-14",
				size === "2xl" && " h-16 min-h-16 w-16 min-w-16",
				size === "3xl" && " h-24 min-h-24 w-24 min-w-24",
				size === "4xs" && "h-4 min-h-4 w-4 min-w-4",
			)}`}
		>
			{!src || isError ? (
				<p className={cx(textClassName, "font-repro mt-[1px]")}>
					{alt?.[0]?.toUpperCase() ?? ""}
				</p>
			) : (
				<ImageComponent
					width={dimensions}
					height={dimensions}
					className="aspect-square h-full w-full rounded-full object-cover object-center"
					src={src}
					onError={() => setIsError(() => true)}
					alt={""}
				/>
			)}
		</div>
	);
};
