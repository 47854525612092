import { ReactNode, useMemo, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useApiErrorHandler } from "~/utils/api";
import { trpc } from "./trpc";
import superjson from "superjson";
import {
	ADMIN_AUTH_TOKEN_KEY,
	AGENCY_PUBLIC_ID,
	AUTH_TOKEN_KEY,
	CREATOR_PROFILE_UUID_KEY,
} from "~/utils/api/client";
import { httpBatchLink, httpLink, splitLink } from "@trpc/react-query";

interface QueryProviderProps {
	children: ReactNode;
}

const headers = () => {
	try {
		const headers: Record<string, string> = {};

		const authToken = localStorage.getItem(AUTH_TOKEN_KEY);
		if (authToken) {
			headers["Authorization"] = `Bearer ${authToken}`;
		}

		const creatorProfileUuid = sessionStorage.getItem(CREATOR_PROFILE_UUID_KEY);
		if (creatorProfileUuid) {
			headers["x-july-creator-profile"] = creatorProfileUuid;
		}

		const agencyPublicId = localStorage.getItem(AGENCY_PUBLIC_ID);
		if (agencyPublicId) {
			headers["x-july-agency-public-id"] = agencyPublicId;
		}

		// Only include admin token if it exists
		const adminToken = localStorage.getItem(ADMIN_AUTH_TOKEN_KEY);
		if (adminToken) {
			headers["x-july-admin-authorization"] = `Bearer ${adminToken}`;
		}

		return headers;
	} catch (e) {
		// Expected when localStorage is not available (e.g. in some incognito
		// modes)
		return {};
	}
};

export const QueryProvider: React.FC<QueryProviderProps> = ({ children }) => {
	const { handleApiError } = useApiErrorHandler();

	const queryClient = useMemo(
		() =>
			new QueryClient({
				defaultOptions: {
					queries: {
						onError: (error) => {
							handleApiError(error);
						},
					},
				},
			}),
		[handleApiError],
	);

	const [trpcClient] = useState(() =>
		trpc.createClient({
			transformer: superjson,
			links: [
				// Allow us to force requests to not batch. Slow endpoints (like getting
				// matches) should use this.
				splitLink({
					condition(op) {
						return op.context.skipBatch === true;
					},
					true: httpLink({
						url: "/api/trpc",
						headers,
					}),
					false: httpBatchLink({
						url: "/api/trpc",
						headers,
					}),
				}),
			],
		}),
	);

	return (
		<trpc.Provider client={trpcClient} queryClient={queryClient}>
			<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
		</trpc.Provider>
	);
};
