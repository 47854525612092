import * as Tabs from "@radix-ui/react-tabs";
import { cx } from "../classnames";

export const Root: React.FC<Tabs.TabsProps> = ({ className, ...rest }) => {
	return (
		<Tabs.Root
			className={cx("font-repro h-fit w-fit", className)}
			{...rest}
		></Tabs.Root>
	);
};

interface ListProps extends Tabs.TabsListProps {
	size?: "xs" | "sm" | "md" | "lg";
}

export const List: React.FC<ListProps> = ({
	size = "md",
	className,
	children,
	...rest
}) => {
	return (
		<Tabs.List
			data-size={size}
			className={`${size === "lg" ? "text-button-md" : "text-button-sm"} ${cx(
				"bg-surface-hover-1 group flex w-fit items-center rounded-full p-[3px]",
				className,
			)}`}
			{...rest}
		>
			{children}
		</Tabs.List>
	);
};

export const Trigger: React.FC<Tabs.TabsTriggerProps> = ({
	value,
	className,
	children,
	...rest
}) => {
	return (
		<Tabs.Trigger
			value={value}
			className={cx(
				"hover:text-text-primary data-[state=active]:text-text-primary text-text-tertiary data-[state=active]:bg-surface-hover-1 flex h-full cursor-pointer items-center justify-center rounded-full px-3 transition-colors group-data-[size=lg]:h-[42px] group-data-[size=md]:h-[34px] group-data-[size=sm]:h-[26px] group-data-[size=xs]:h-[22px] group-data-[size=lg]:px-4",
				className,
			)}
			{...rest}
		>
			{children}
		</Tabs.Trigger>
	);
};
