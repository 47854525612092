import { z } from "zod";
import { TagColorOptionsSchema } from "./utils";
import { BrandPreviewSchema } from "./agency-brands";

export const DealsLayoutSchema = z.enum(["kanban", "list"]);
export type DealsLayout = z.infer<typeof DealsLayoutSchema>;

export const DealFilterSchema = z.enum(["createdBy", "creatorIs"]);
export type DealFilter = z.infer<typeof DealFilterSchema>;

export const DealOrderSchema = z.enum(["manual", "taskDueDate"]);
export type DealOrder = z.infer<typeof DealOrderSchema>;

export const DealTaskStatusSchema = z.enum(["complete", "incomplete"]);
export type DealTaskStatus = z.infer<typeof DealTaskStatusSchema>;

export const CalendarLayoutSchema = z.enum(["month", "week"]);
export type CalendarLayout = z.infer<typeof CalendarLayoutSchema>;

export const DealContractStatusSchema = z.enum([
	"Offer Out",
	"Pending Agreement",
	"Talent Signature",
	"Brand Signature",
	"Fully Executed",
	"Redlining",
]);
export type DealContractStatus = z.infer<typeof DealContractStatusSchema>;

export const DealInvoiceStatusSchema = z.enum([
	"Draft",
	"Sent",
	"Paid",
	"Remitted",
	"Overdue",
	"Void",
	"Handled by third party",
	"Paid directly to talent",
]);
export type DealInvoiceStatus = z.infer<typeof DealInvoiceStatusSchema>;

export const DealEntryCommissionTypeSchema = z.enum([
	"manager",
	"custom",
	"agency",
]);
export type DealEntryCommissionType = z.infer<
	typeof DealEntryCommissionTypeSchema
>;

export const DealDeliverableSchema = z.object({
	title: z.string().min(2),
	amount: z.number().optional(),
});

export type DealDeliverable = z.infer<typeof DealDeliverableSchema>;

export const DealDeliverablesSchema = z.array(DealDeliverableSchema);
export type DealDeliverables = z.infer<typeof DealDeliverablesSchema>;

export const DealEntryTalentTypeSchema = z.enum(["roster", "custom"]);
export type DealEntryTalentType = z.infer<typeof DealEntryTalentTypeSchema>;

export const DealEntryCommissionSchema = z.object({
	uuid: z.string().uuid(),
	commission: z.number(),
	manager: z
		.object({
			uuid: z.string().uuid(),
			displayName: z.string(),
			profilePictureUrl: z.string().optional(),
		})
		.optional(),
	name: z.string().optional(),
	type: DealEntryCommissionTypeSchema,
});
export type DealEntryCommission = z.infer<typeof DealEntryCommissionSchema>;

export const DealEntrySchema = z.object({
	uuid: z.string().uuid(),
	talent: z
		.object({
			uuid: z.string().uuid(),
			creatorProfileUuid: z.string().uuid(),
			displayName: z.string(),
			profilePictureUrl: z.string().optional(),
		})
		.optional(),
	deliverables: DealDeliverablesSchema,
	talentType: DealEntryTalentTypeSchema,
	talentName: z.string().optional(),
	commissions: z.array(DealEntryCommissionSchema),
});
export type DealEntry = z.infer<typeof DealEntrySchema>;

export const DealTaskSchema = z.object({
	uuid: z.string().uuid(),
	title: z.string().min(1),
	status: DealTaskStatusSchema,
	dueDate: z.coerce.date().optional(),
	color: TagColorOptionsSchema,
});
export type DealTask = z.infer<typeof DealTaskSchema>;

export const DealTaskPreviewSchema = DealTaskSchema.extend({
	deal: z.object({
		uuid: z.string().uuid(),
		columnUuid: z.string().uuid(),
		title: z.string(),
	}),
});
export type DealTaskPreview = z.infer<typeof DealTaskPreviewSchema>;

export const DealTasksSchema = z.array(DealTaskSchema);
export type DealTasks = z.infer<typeof DealTasksSchema>;

export const DealFileSchema = z.object({
	uuid: z.string().uuid(),
	fileUrl: z.string().url().optional(),
	name: z.string(),
	createdAt: z.coerce.date().optional(),
	type: z.string(),
});
export type DealFile = z.infer<typeof DealFileSchema>;

export const DealContractSchema = z.object({
	uuid: z.string().uuid(),
	status: DealContractStatusSchema,
	file: DealFileSchema.optional(),
});
export type DealContract = z.infer<typeof DealContractSchema>;

export const DealInvoiceSchema = z.object({
	uuid: z.string().uuid(),
	amount: z.number().optional(),
	invoiceNumber: z.string().optional(),
	dueDate: z.coerce.date().optional(),
	sentDate: z.coerce.date().optional(),
	paidDate: z.coerce.date().optional(),
	remittedDate: z.coerce.date().optional(),
	status: DealInvoiceStatusSchema,
	file: DealFileSchema.optional(),
});
export type DealInvoice = z.infer<typeof DealInvoiceSchema>;

export const AgencyDealManagerSchema = z.object({
	uuid: z.string().uuid(),
	name: z.string(),
	profilePictureUrl: z.string().optional(),
	commission: z.number(),
});
export type AgencyDealManager = z.infer<typeof AgencyDealManagerSchema>;

export const AgencyDealSchema = z.object({
	uuid: z.string().uuid(),
	title: z.string().min(1),
	brandName: z.string(),
	entries: z.array(DealEntrySchema),
	column: z.object({
		uuid: z.string().uuid(),
		title: z.string(),
		tagColor: TagColorOptionsSchema,
	}),
	tasks: z.array(DealTaskSchema),
	notes: z.string().optional(),
	files: z.array(DealFileSchema),
	contract: DealContractSchema.optional(),
	managers: AgencyDealManagerSchema.array(),
	invoice: DealInvoiceSchema.optional(),
	brands: z.array(BrandPreviewSchema),
});

export type AgencyDeal = z.infer<typeof AgencyDealSchema>;

export const AgencyDealPreviewSchema = z.object({
	uuid: z.string().uuid(),
	title: z.string().min(1),
	brandName: z.string(),
	column: z.object({
		uuid: z.string().uuid(),
		title: z.string(),
		tagColor: TagColorOptionsSchema,
	}),
	creators: z.array(
		z.object({
			name: z.string(),
			profilePictureUrl: z.string().optional(),
		}),
	),
	deliverables: z.object({
		count: z.number(),
		amount: z.number(),
	}),
	allTasksCompleted: z.boolean(),
	tasks: z.array(DealTaskSchema),
	order: z.number(),
	createdBy: z.string().uuid(),
	managers: AgencyDealManagerSchema.array(),
	contractStatus: DealContractStatusSchema.optional(),
	totalFiles: z.number(),
	nextTask: DealTaskSchema.optional(),
	createdAt: z.coerce.date(),
});

export type AgencyDealPreview = z.infer<typeof AgencyDealPreviewSchema>;

export const AgencyDealFilterSchema = z.object({
	uuid: z.string(),
	createdByUuid: z.string().optional(),
	managerUuid: z.string(),
});

export type AgencyDealFilter = z.infer<typeof AgencyDealFilterSchema>;

export const AgencyDealsColumnSchema = z.object({
	uuid: z.string().uuid(),
	order: z.number(),
	title: z.string(),
	tagColor: TagColorOptionsSchema,
});
export type AgencyDealsColumn = z.infer<typeof AgencyDealsColumnSchema>;
