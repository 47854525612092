import { z } from "zod";
import { DealTaskStatusSchema } from "./agency-deals";
import { AgencyPaymentsOverviewTimePeriod } from "./agency-payments";
import { TagColorOptionsSchema } from "./utils";

export const HomeTaskPreviewSchema = z.object({
	uuid: z.string().uuid(),
	title: z.string(),
	status: DealTaskStatusSchema,
	dueDate: z.coerce.date().optional(),
	agencyDeal: z.object({
		uuid: z.string().uuid(),
		title: z.string(),
	}),
	color: TagColorOptionsSchema,
});

export type HomeTaskPreview = z.infer<typeof HomeTaskPreviewSchema>;

export const RevenuePreviewSchema = z.object({
	uuid: z.string().uuid(),
	talent: z.object({
		uuid: z.string().uuid(),
		name: z.string(),
		profilePictureUrl: z.string().url().optional(),
	}),
	dealCount: z.number(),
	dealValue: z.number(),
	agencyEarnings: z.number(),
	talentEarnings: z.number(),
});

export type RevenuePreview = z.infer<typeof RevenuePreviewSchema>;

export const AgencyHomeRevenueQuerySchema = z.object({
	numMonths: AgencyPaymentsOverviewTimePeriod.optional(),
});

export type AgencyHomeRevenueQuery = z.infer<
	typeof AgencyHomeRevenueQuerySchema
>;
