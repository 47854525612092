import { useRouter } from "next/router";
import posthog from "posthog-js";
import { useEffect } from "react";

/**
 * Initialize posthog
 *
 * Note that this has to be done outside of react's lifecycle hooks as otherwise
 * useFeatureFlags attempts to access data before it gets initialized.
 */
if (typeof window !== "undefined") {
	posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY ?? "", {
		api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST ?? "https://app.posthog.com",
		// Disable auto capturing events in development
		loaded: (posthog) => {
			if (process.env.NODE_ENV === "development") posthog.opt_out_capturing();
		},
		disable_surveys: true,
	});
}

/**
 * Subscribe posthog to route changes
 */
export const useInitPosthog = () => {
	const router = useRouter();

	useEffect(() => {
		if (window === undefined) {
			return;
		}

		// Manually submit page view events as Next's routing doesn't play well with
		// posthog by default
		const handleRouteChange = () => posthog.capture("$pageview");
		router.events.on("routeChangeComplete", handleRouteChange);
		return () => {
			router.events.off("routeChangeComplete", handleRouteChange);
		};
	}, [router.events]);
};
