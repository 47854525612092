import { z } from "zod";
import { TagColorOptionsSchema } from "./utils";

/**
 *
 */
export const BrandCategorySchema = z.object({
	uuid: z.string().uuid(),
	createdAt: z.date(),
	name: z.string(),
	color: TagColorOptionsSchema,
	order: z.number(),
});
export type BrandCategory = z.infer<typeof BrandCategorySchema>;

/**
 *
 */
export const BrandMarketingAgencyContactSchema = z.object({
	uuid: z.string().uuid(),
	createdAt: z.date(),
	role: z.string(),
	name: z.string(),
	email: z.string(),
});
export type BrandMarketingAgencyContact = z.infer<
	typeof BrandMarketingAgencyContactSchema
>;

/**
 *
 */
export const BrandContactSchema = z.object({
	uuid: z.string().uuid(),
	createdAt: z.date(),
	role: z.string(),
	name: z.string(),
	email: z.string(),
});
export type BrandContact = z.infer<typeof BrandContactSchema>;

/**
 *
 */
export const BrandMarketingAgencyPreviewSchema = z.object({
	uuid: z.string().uuid(),
	updatedAt: z.date(),
	name: z.string(),

	logoUrl: z.string().url().optional(),

	contacts: z.array(BrandMarketingAgencyContactSchema),
});
export type BrandMarketingAgencyPreview = z.infer<
	typeof BrandMarketingAgencyPreviewSchema
>;

/**
 *
 */
export const BrandPreviewSchema = z.object({
	uuid: z.string().uuid(),
	updatedAt: z.date(),
	name: z.string(),

	logoUrl: z.string().url().optional(),

	contacts: z.array(BrandContactSchema),
	marketingAgencies: z.array(BrandMarketingAgencyPreviewSchema),
	categories: z.array(BrandCategorySchema),
});
export type BrandPreview = z.infer<typeof BrandPreviewSchema>;

/**
 *
 */
export const BrandMarketingAgencySchema = z.object({
	uuid: z.string().uuid(),
	createdAt: z.date(),
	updatedAt: z.date(),
	name: z.string(),
	notes: z.string(),

	logoUrl: z.string().url().optional(),

	contacts: z.array(BrandMarketingAgencyContactSchema),
});
export type BrandMarketingAgency = z.infer<typeof BrandMarketingAgencySchema>;

/**
 *
 */
export const BrandSchema = z.object({
	uuid: z.string().uuid(),
	createdAt: z.date(),
	updatedAt: z.date(),
	name: z.string(),
	notes: z.string(),

	logoUrl: z.string().url().optional(),

	contacts: z.array(BrandContactSchema),
	marketingAgencies: z.array(BrandMarketingAgencyPreviewSchema),
	categories: z.array(BrandCategorySchema),
});
export type Brand = z.infer<typeof BrandSchema>;
